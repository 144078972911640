import Api from '@common/services/api';

export const CORE_RECEIVE_USER = 'core/RECEIVE_USER';

const fetchNetworkUser = (userId: string) => async (dispatch: any, getState: any) => {
  const { organisation: { selected } } = getState();
  const { data: user } = await Api.get(`/v2/organisations/${selected.id}/users/${userId}`);
  return dispatch({
    type: CORE_RECEIVE_USER,
    user
  });
};

export default fetchNetworkUser;
