import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { EPermissions } from '@common/definitions';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import Access from '@common/components/access';

import { useAppSelector } from '@common/hooks';
import { removeUserFromFunctionGroup } from '@modules/core/actions';
import { ProfileMembershipsProps } from '@modules/core/types';
import { AddUserToFunctionGroup } from '@modules/core/forms/add-user-to-function-group';
import * as organisationSelectors from '@modules/organisation/selectors/organisation';
import { UserProfileTypes } from '@modules/core/definitions';

interface FunctionGroupsProps extends ProfileMembershipsProps {
  syncIcon: React.ReactNode;
  type?: UserProfileTypes;
}

const FunctionGroups = ({ user, syncIcon, type }: FunctionGroupsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams<{ networkId?: string, userId: string }>();
  const networks = useAppSelector(organisationSelectors.networks);

  const handleRemove = (membership: any) => {
    const networkId = params.networkId || type === UserProfileTypes.OWN
      ? networks.find((n) => n.permissions.includes(EPermissions.NETWORK_USERS_UPDATE))?.id
      : undefined;
    return dispatch(removeUserFromFunctionGroup(user.id, membership.id, networkId));
  };

  return (
    <div className="ProfileMemberships__Group">
      <b>{t('core:function_groups')}</b>
      {user.scopes.functions.map((orgFunction: any) => {
        const isSynced = !!orgFunction?.added_by_integration;
        return (
          <div
            className={
              `ProfileMemberships__Row${isSynced ? ' synced' : ''}`
            }
            key={orgFunction.id}
          >
            <Icon type="assignment_ind__filled" />
            <span className="ProfileMemberships__Name">{orgFunction.name}</span>

            <Access
              permissions={EPermissions.ORGANISATION_USERS_UPDATE}
              networkPermissions={EPermissions.NETWORK_USERS_UPDATE}
              networks={user.scopes.networks.filter((network) => networks.find((n) => n.id === network.id))}
            >
              {isSynced && syncIcon}

              {!isSynced && (
                <Dropdown
                  className="MembershipRowDropdown"
                  alignRight
                  trigger={<Icon type="more_vert" />}
                >
                  <ConfirmButton
                    title={t('core:remove_from_community_confirmation', { community: orgFunction.name })}
                    onConfirm={() => handleRemove(orgFunction)}
                  >
                    <Dropdown.Item icon="delete__filled" danger>
                      {t('core:profile_remove_membership', { context: 'function' })}
                    </Dropdown.Item>
                  </ConfirmButton>
                </Dropdown>
              )}
            </Access>
          </div>
        );
      })}

      <Access
        permissions={EPermissions.ORGANISATION_USERS_UPDATE}
        networkPermissions={EPermissions.NETWORK_USERS_UPDATE}
        networks={user.scopes.networks.filter((network) => networks.find((n) => n.id === network.id))}
      >
        <AddUserToFunctionGroup user={user} type={type} />
      </Access>
    </div>
  );
};

export default FunctionGroups;
