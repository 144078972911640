import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useCanAccess } from '@common/hooks';
import { EPermissions } from '@common/definitions';
import { mergeNamesToString } from '@common/utils/merged-names-text';

import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import Access from '@common/components/access/access';
import AdminRolesForm from '../../../admin/forms/admin';
import AddUserToCommunity from '@modules/core/forms/add-user-to-community';

import { removeUserFromCommunity } from '@modules/core/actions';
import { fetchRoles, updateUserRoles } from '@modules/network/actions';
import { fetchPermissionGroups } from '@modules/organisation/actions';
import * as organisationSelectors from '@modules/organisation/selectors/organisation';
import { ProfileMembershipsProps } from '@modules/core/types';
import { Network } from '@common/types/objects';

interface CommunitiesProps extends ProfileMembershipsProps {
  syncIcon: React.ReactNode;
}

const Communities = ({ user, syncIcon }: CommunitiesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [networkToEditRoles, setNetworkToEditRoles] = useState<Network | null>(null);
  const roles = useAppSelector(organisationSelectors.roles);
  const hasOrganisationRemovePermission = useCanAccess({ permissions: EPermissions.ORGANISATION_NETWORK_ADMINS_REMOVE });

  const handleRemove = useCallback((membership: any) => {
    return dispatch(removeUserFromCommunity(user.id, membership.id, hasOrganisationRemovePermission));
  }, [hasOrganisationRemovePermission, dispatch, user.id]);

  useEffect(() => {
    if (networkToEditRoles) dispatch(fetchRoles(networkToEditRoles.id));
  }, [dispatch, networkToEditRoles]);

  return (
    <div className="ProfileMemberships__Group">
      <b>{t('core:communities')}</b>
      { /*
        do not rely on user.scopes.networks[].memberships[], see comments
        on PD-8672
      */ }
      {user.scopes.networks.map((network: any) => {
        const isSynced = !!network?.added_by_integration;
        return (
          <div
            className={
              `ProfileMemberships__Row${isSynced ? ' synced' : ''}`
            }
            key={network.id}
          >
            <Icon type="public__filled" />
            <div className="ProfileMemberships__Labels">
              <span className="ProfileMemberships__Name">{network.name}</span>
              <span className="ProfileMemberships__Roles">
                {mergeNamesToString(network.roles, 2, 'name')}
              </span>
            </div>

            <Access
              permissions={[EPermissions.ORGANISATION_NETWORK_ADMINS_ADD, EPermissions.ORGANISATION_NETWORK_ADMINS_REMOVE]}
              networkPermissions={[EPermissions.NETWORK_NETWORK_ADMINS_ADD, EPermissions.NETWORK_NETWORK_ADMINS_REMOVE]}
              networks={[network]}
            >
              {isSynced && syncIcon}
              <Dropdown
                className="MembershipRowDropdown"
                alignRight
                trigger={<Icon type="more_vert" />}
              >
                <Access
                  permissions={EPermissions.ORGANISATION_NETWORK_ADMINS_ADD}
                  networkPermissions={EPermissions.NETWORK_NETWORK_ADMINS_ADD}
                  networks={[network]}
                >
                  <Dropdown.Item icon="local_police" onClick={() => setNetworkToEditRoles(network)}>
                    {t('core:profile_edit_membership', { context: 'network' })}
                  </Dropdown.Item>
                </Access>
                <Access
                  permissions={EPermissions.ORGANISATION_NETWORK_ADMINS_REMOVE}
                  networkPermissions={EPermissions.NETWORK_NETWORK_ADMINS_REMOVE}
                  networks={[network]}
                  and={!isSynced}
                >
                  <ConfirmButton
                    title={t('core:remove_from_community_confirmation', { community: network.name })}
                    onConfirm={() => handleRemove(network)}
                  >
                    <Dropdown.Item icon="delete__filled" danger>
                      {t('core:profile_remove_membership', { context: 'network' })}
                    </Dropdown.Item>
                  </ConfirmButton>
                </Access>
              </Dropdown>
            </Access>
          </div>
        );
      })}

      {networkToEditRoles && (
        <AdminRolesForm
          show
          network={networkToEditRoles}
          updateAdmin={updateUserRoles}
          admin={{ ...user, roles: networkToEditRoles.roles || [] }}
          onClose={() => setNetworkToEditRoles(null)}
          roles={roles.network}
          fetchGroups={(level) => dispatch(fetchPermissionGroups(level))}
        />
      )}

      <Access permissions={EPermissions.ORGANISATION_USERS_UPDATE}>
        <AddUserToCommunity user={user} />
      </Access>
    </div>

  );
};

export default Communities;
