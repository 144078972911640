import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EPermissions } from '@common/definitions';
import { useAppSelector } from '@common/hooks';

import Dropdown from '@common/components/dropdown';
import Icon from '@common/components/icon';
import Access from '@common/components/access/access';

import AdminForm from '@modules/admin/forms/admin';
import { fetchPermissionGroups, fetchRoles } from '@modules/organisation/actions';
import * as organisationSelectors from '@modules/organisation/selectors/organisation';
import { ProfileMembershipsProps } from '@modules/core/types';

const Organisations = ({ user }: ProfileMembershipsProps) => {
  const [isEditRolesModalVisible, setIsEditRolesModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roles = useAppSelector(organisationSelectors.roles);

  useEffect(() => {
    if (isEditRolesModalVisible) dispatch(fetchRoles());
  }, [dispatch, isEditRolesModalVisible]);

  return (
    <>
      <div className="ProfileMemberships__Group">
        <b>{t('core:organization')}</b>
        <div className="ProfileMemberships__Row">
          <Icon type="business__filled" />
          <span className="ProfileMemberships__Name">{user.scopes.organisation.name}</span>
          <Access
            permissions={[
              EPermissions.ORGANISATION_ORGANISATION_ADMINS_ADD,
              EPermissions.ORGANISATION_ORGANISATION_ADMINS_REMOVE,
              EPermissions.GLOBAL_ORGANISATION_ADMINS_ADD,
              EPermissions.GLOBAL_ORGANISATION_ADMINS_REMOVE,
            ]}
          >
            <Dropdown
              className="MembershipRowDropdown"
              alignRight
              trigger={<Icon type="more_vert" />}
            >
              <Dropdown.Item icon="local_police" onClick={() => setIsEditRolesModalVisible(true)}>
                {t('core:profile_edit_membership', { context: 'organisation' })}
              </Dropdown.Item>
            </Dropdown>
          </Access>
        </div>
      </div>

      {isEditRolesModalVisible && (
        <AdminForm
          show
          network={undefined}
          admin={{ ...user, roles: user.scopes.organisation.roles || [] }}
          onClose={() => setIsEditRolesModalVisible(false)}
          roles={roles.organisation}
          fetchGroups={(level: number) => dispatch(fetchPermissionGroups(level))}
        />
      )}
    </>
  );
};

export default Organisations;
