import React from 'react';
import { useParams, generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import Container from '@common/components/container';
import Bar from '@common/components/bar';
import { SearchBar } from '@common/components/search-bar';
import DocumentsBrowser from '@modules/documents/components/documents-browser';
import { DocumentsHeaderActionButtons } from '@modules/documents/components/documents-header-action-buttons';

import { useSearchParams, usePageTracker } from '@common/hooks';
import { useFolderDocuments } from '@modules/documents/hooks/use-folder-documents';
import { useDocuments } from '@modules/documents/context';

import { EEventNames } from '@services/analytics';
import { DocumentsRouteParams } from '../../../../documents/types';
import { ExtendedUser, LoggedUser } from '@common/types/objects';

import './personal-documents.scss';

type PersonalDocumentsOwnProps = {
  profilePath: string;
  user: ExtendedUser | LoggedUser | undefined;
};

export const PersonalDocuments = ({ profilePath, user }: PersonalDocumentsOwnProps) => {
  usePageTracker(EEventNames.VIEWED_ALL_PERSONAL_DOCUMENTS);

  const { t } = useTranslation();
  const params = useParams<DocumentsRouteParams>();
  const { searchParams, setSearchParam } = useSearchParams<{ search?: string }>();
  const documentsContext = useDocuments();
  const folderDocuments = useFolderDocuments({
    ...documentsContext, searchParams, userId: user?.id, folderId: params.folderId,
  });
  const getNextPath = (folderId: string) => generatePath(`${profilePath}/personal-documents/${folderId}`, params);
  const handleSetSearchTerm = (newSearchTerm: Parameters<typeof setSearchParam>[1]) => setSearchParam(
    'search', newSearchTerm, `${profilePath}/personal-documents`,
  );

  return (
    <Container.Content>
      <Bar>
        <h2>{t('core:personal_documents_header', { firstName: user?.first_name })}</h2>
        <SearchBar
          placeholder={t('documents:search_placeholder')}
          onSearch={handleSetSearchTerm}
          defaultValue={searchParams.search}
          key={`${params.filter}${params.folderId}`} // remounting when those change in order to clear the input
        />
        <DocumentsHeaderActionButtons
          params={params}
          userId={user?.id}
          onUpdate={folderDocuments.onUpdate}
        />
      </Bar>
      <DocumentsBrowser
        getFolderPath={getNextPath}
        userId={user?.id}
        {...folderDocuments}
      />
    </Container.Content>
  );
};
