import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@common/components/card';
import { Icon } from '@common/components/icon';
import Tooltip from '@common/components/tooltip';

import Communities from '../communities';
import FunctionGroups from '../functions';
import Organisations from '../organisations';

import { ProfileMembershipsProps } from '@modules/core/types';

const SyncedIcon = memo(() => {
  const { t } = useTranslation();
  return (
    <Tooltip placement="left" title={t('core:synced_by_the_integration')}>
      <Icon type="sync_alt" className="syncIcon" color="orange" />
    </Tooltip>
  );
});

export const ProfileMemberships = ({ user, type }: ProfileMembershipsProps) => {
  return (
    <Card containerClassName="ProfileMemberships">
      <Card.Content>
        <Organisations user={user} />
        <Communities
          user={user}
          syncIcon={<SyncedIcon />}
        />
        <FunctionGroups
          user={user}
          syncIcon={<SyncedIcon />}
          type={type}
        />
      </Card.Content>
    </Card>
  );
};
