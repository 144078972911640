import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Redirect, useRouteMatch, useHistory, Route, generatePath
} from 'react-router';
import { useDispatch } from 'react-redux';

import { TopNavigationBar } from '@common/components/navigation-bar';
import { Spinner } from '@common/components/spinner';
import { Button } from '@common/components/button';
import Access from '@common/components/access';
import { Switch } from '@common/components/router';
import Container from '@common/components/container';
import { EComponentTypes, EPermissions, EPlanPackageConfig } from '@common/definitions';
import { AlertService } from '@services/alert';
import { AboutProfile } from './about-profile';
import { ProfileForm } from '../../forms/profile';
import UserProfileForms from './user-profile-forms';
import UserProfileSurveys from './user-profile-surveys';
import UserProfileELearning from './user-profile-elearning';
import UserProfileOnboarding from './user-profile-onboarding';
import { PersonalDocuments } from './personal-documents';

import { useAppSelector, useCanAccess } from '@common/hooks';

import fetchUser from '../../actions/fetch-user';
import { deleteUser } from '../../../admin/actions';
import UserActions from '@modules/admin/components/user-actions';
import resendInvitation from '../../../network/actions/resend-invitation';
import { resendInvitation as resendInvitationOrg } from '@modules/admin/actions/index';

import * as usersSelector from '../../selectors/users';
import * as loggedUserSelector from '../../selectors/logged-user';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import * as networkSelectors from '@modules/network/selectors/network';

import { UserProfileTypes } from '../../definitions';
import fetchNetworkUser from '@modules/core/actions/fetch-network-user';
import deleteNetworkUser from '@modules/network/actions/delete-user';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { DocumentsProvider } from '@modules/documents/context';

import '../../../survey/containers/responses/responses.scss';

type ProfileContainerProps = {
  userId: string | undefined;
  type: UserProfileTypes;
};

const ProfileContainer = ({ userId, type }: ProfileContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path, url, params } = useRouteMatch();
  const loggedUser = useAppSelector(loggedUserSelector.selected);
  const user = useAppSelector((state) => {
    if (userId) {
      const data = usersSelector.byId(state, userId);
      if (loggedUser.id === userId) {
        return {
          ...data,
          ...loggedUser,
        };
      }
      return data;
    }
    return undefined;
  });
  const selectedNetwork = useAppSelector((store) => store.network.selected);
  const organisation = useAppSelector((state) => orgSelectors.selected(state));
  const network = useAppSelector((state) => networkSelectors.selected(state));

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const effect = async () => {
      setIsFetching(true);

      const inAdminSide = url.startsWith('/admin');

      if (userId === loggedUser.id && !url.includes('/profile')) {
        const profilePath = inAdminSide ? '/admin/profile/about' : '/profile/about';
        return history.replace(profilePath);
      }
      const action = inAdminSide ?
        fetchUser(userId) :
        fetchNetworkUser(userId || '');
      await dispatch(action);

      setIsFetching(false);
    };
    effect();
  }, [userId, organisation, setIsFetching, network, dispatch]);

  const handleOpenConversation = () => {
    if (!user) return;
    history.push(`${path.includes('admin') ? '/admin' : ''}/conversations/users/${user.id}`);
  };

  const handleRemoveUser = async () => {
    if (!user) return;

    try {
      const inAdminSide = url.startsWith('/admin');
      const action = inAdminSide ? deleteUser(user.id) : deleteNetworkUser(user.id);
      await dispatch(action);

      AlertService.success(t('core:removed_user', { context: 'success' }));
      history.push(inAdminSide ? '/admin/users' : `/networks/${selectedNetwork}/users`);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('core:removed_user', { context: 'warning' }),
          error: t('core:removed_user', { context: 'error' }),
        });
      }
    }
  };

  const handleResendInvitation = useCallback(async () => {
    if (!user) return;

    try {
      if (path.includes('admin')) {
        await dispatch(resendInvitationOrg(user));
      } else {
        await dispatch(resendInvitation(user));
      }
      AlertService.success(
        <Trans
          i18nKey="network:user_reinvite_success"
          values={{ email: user.email }}
          components={[<b>a</b>]}
        />,
      );
    } catch (response: any) {
      AlertService.forStatus(response, {
        warning: t('network:user_reinvite_warning'),
        error: t('network:user_reinvite_error'),
      });
    }
  }, [user, path, dispatch, t]);

  const {
    isAvailable: canAccessPersonalDocuments, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.DOCUMENTS_USER_SPECIFIC);

  const canViewDocuments = useCanAccess({
    permissions: [EPermissions.ORGANISATION_PERSONAL_DOCUMENTS_VIEW_ALL, EPermissions.ORGANISATION_PERSONAL_DOCUMENTS_CREATE],
    components: EComponentTypes.DOCUMENTS,
  });

  const canViewSurveyStats = useCanAccess({
    permissions: EPermissions.ORGANISATION_SURVEYS_VIEW,
    components: [EComponentTypes.SURVEYS, EComponentTypes.SURVEYS_PREMIUM],
  });

  const canViewFormStats = useCanAccess({
    permissions: [EPermissions.ORGANISATION_FORMS_VIEW_ALL, EPermissions.ORGANISATION_CREATOR_FORMS_VIEW],
    components: [EComponentTypes.FORMS, EComponentTypes.FORMS_PREMIUM],
  });

  const canViewOnboardingStats = useCanAccess({
    permissions: [EPermissions.ORGANISATION_ONBOARDING_STATISTICS, EPermissions.ORGANISATION_ONBOARDING_COURSES_STATISTICS],
    networks: user?.scopes?.networks || [],
    networkPermissions: EPermissions.NETWORK_ONBOARDING_STATISTICS,
    components: EComponentTypes.ONBOARDING,
  });

  const canViewELearningStats = useCanAccess({
    permissions: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW,
    networks: user?.scopes?.networks || [],
    networkPermissions: EPermissions.NETWORK_ELEARNING_STATISTICS,
    components: EComponentTypes.ACADEMY,
  });

  const canUpdateUser = useCanAccess({
    permissions: EPermissions.ORGANISATION_USERS_UPDATE,
    networkPermissions: EPermissions.NETWORK_USERS_UPDATE,
    networks: user?.scopes?.networks || [],
  });

  const tabs = useMemo(() => {
    const slots = [
      {
        name: t('core:profile_tabs_label_about'),
        to: generatePath(`${path}/about`, params)
      },
      canViewDocuments ?
        {
          name: t('core:profile_tabs_label_documents'),
          to: generatePath(`${path}/personal-documents`, params),
          onClick: (e: any) => {
            if (!canAccessPersonalDocuments) {
              e.preventDefault();
              showUpgradeModal();
            }
          },
        } :
        undefined,
      canViewOnboardingStats ?
        {
          name: t('core:tab_onboarding'),
          to: generatePath(`${path}/onboarding`, params)
        } :
        undefined,
      canViewELearningStats ?
        {
          name: t('core:tab_eLearning'),
          to: generatePath(`${path}/elearning`, params)
        } :
        undefined,
      canViewSurveyStats ?
        {
          name: t('core:tab_surveys'),
          to: generatePath(`${path}/surveys`, params)
        } :
        undefined,
      canViewFormStats ?
        {
          name: t('core:tab_forms'),
          to: generatePath(`${path}/forms`, params)
        } :
        undefined
    ].filter((tab) => !!tab);

    if (slots.length <= 1) {
      // we would only have the "About" tab, no point in rendering only one tab
      return undefined;
    }
    return slots;
  }, [
    t, canViewDocuments, canViewELearningStats, canViewOnboardingStats,
    canViewSurveyStats, canViewFormStats
  ]);

  if (!user || isFetching) {
    return (
      <div className="AsyncSpinner">
        <Spinner />
      </div>
    );
  }

  const onEdit = () => history.push(`${url}/about/edit`);

  return (
    <Container name="ProfileSettings">
      <TopNavigationBar
        image={user?.profile_img || ''}
        title={user?.full_name}
        tabs={tabs}
        action={(
          <>
            {type !== UserProfileTypes.OWN && (
              <Button icon="chat__filled" onClick={handleOpenConversation}>
                {t('core:send_message')}
              </Button>
            )}
            <Access
              permissions={[
                EPermissions.ORGANISATION_USERS_CREATE,
                EPermissions.ORGANISATION_USERS_UPDATE,
                EPermissions.ORGANISATION_USERS_REMOVE,
              ]}
              networkPermissions={[
                EPermissions.NETWORK_USERS_UPDATE,
                EPermissions.NETWORK_USERS_REMOVE,
                EPermissions.NETWORK_USERS_CREATE,
              ]}
              networks={user.scopes?.networks || []}
            >
              <UserActions
                item={user}
                invitedAt={user.scopes?.organisation?.invited_at}
                loggedUser={loggedUser}
                userStatus=""
                networks={user.scopes?.networks || []}
                onEdit={onEdit}
                onDelete={handleRemoveUser}
                onResendInvitation={handleResendInvitation}
              />
            </Access>
          </>
        )}
      />

      {/* Allows to redirect straight to the form or a specific tab inside the form */}
      <Route path={[`${path}/about/edit/:tab`, `${path}/about/edit`]}>
        <ProfileForm
          type={type}
          user={user}
          onClose={() => history.replace(`${url}/about`)}
        />
      </Route>

      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${url}/about`} />} />
        <Route path={`${path}/about`}>
          <AboutProfile user={user} type={type} onEdit={canUpdateUser ? onEdit : undefined} />
        </Route>
        {canViewDocuments && (
          <Route
            path={[`${path}/personal-documents/:folderId`, `${path}/personal-documents`]}
            render={(props) => (
              <DocumentsProvider>
                <PersonalDocuments
                  {...props}
                  profilePath={generatePath(path, params)}
                  user={user}
                />
              </DocumentsProvider>
            )}
          />
        )}
        {
          canViewOnboardingStats && (
            <Route path={`${path}/onboarding`}>
              <UserProfileOnboarding user={user} />
            </Route>
          )
        }
        {
          canViewELearningStats && (
            <Route path={`${path}/elearning`}>
              <UserProfileELearning user={user} />
            </Route>
          )
        }
        {
          canViewFormStats && (
            <Route path={`${path}/forms`}>
              <UserProfileForms user={user} />
            </Route>
          )
        }
        {
          canViewSurveyStats && (
            <Route path={`${path}/surveys`}>
              <UserProfileSurveys user={user} />
            </Route>
          )
        }
      </Switch>
    </Container>
  );
};

export default ProfileContainer;
