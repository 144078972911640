import * as R from 'ramda';
import Api from '../../../common/services/api';

export const NETWORK_UPDATE_USER = 'network/UPDATE_USER';

const whitelist = [
  'first_name', 'last_name', 'dial_code', 'email', 'active_until', 'date_of_birth', 'active_from', 'onboarding',
];

export default (userId, values) => async (dispatch, getState) => {
  const { network: { selected: networkId } } = getState();

  const payload = R.pick(whitelist, values);
  if (values.phone?.number) {
    payload.dial_code = values.phone.dial_code;
    payload.phone_num = values.phone.number;
  } else {
    payload.dial_code = null;
    payload.phone_num = null;
  }

  if (values.date_of_birth) {
    payload.date_of_birth = values.date_of_birth.format('YYYY-MM-DD');
  }

  if (values.functions) {
    await Api.put(`/v2/networks/${networkId}/users/${userId}/functions`, values.functions);
  }

  const { data: user } = await Api.put(`/v2/networks/${networkId}/users/${userId}`, payload);

  dispatch({
    type: NETWORK_UPDATE_USER,
    user,
  });
};
