import React from 'react';
import { useTranslation } from 'react-i18next';

import Permission from '@common/components/permission';
import { EPermissions } from '@common/definitions';
import { Button } from '@common/components/button';
import FolderModalForm from '../../forms/folder-modal-form';
import { FileUploadForm } from '../../forms/file-upload';

import type { PartialBy } from '@common/types/util-types';
import type { DocumentsRouteParams, Document } from '../../types';

type HeaderActionButtonsProps = {
  params: PartialBy<DocumentsRouteParams, 'filter' | 'networkId'>;
  userId?: string;
  onUpdate: (document: Document) => void;
};

export const DocumentsHeaderActionButtons = ({
  params,
  userId,
  onUpdate,
}: HeaderActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Permission name={[EPermissions.NETWORK_DOCUMENTS_CREATE, EPermissions.ORGANISATION_DOCUMENTS_CREATE]}>
      <FolderModalForm
        userId={userId}
        onUpdate={onUpdate}
      >
        <Button size="large" iconRight="add">
          {t('documents:create_folder')}
        </Button>
      </FolderModalForm>
      <FileUploadForm
        parentFolderId={params.folderId}
        userId={userId}
        personalFolder
        onUpdate={onUpdate}
      />
    </Permission>
  );
};
