import React, { memo } from 'react';
import { Emoji } from 'emoji-mart';
import { UserStatus } from '@common/types/objects';
import { UserStatusLabel } from '../profile-image';

type UserStatusPreviewProps = {
  className?: string;
  status: UserStatus
};

const UserStatusPreview = memo(({ className, status }: UserStatusPreviewProps) => (
  <div className={`UserStatusPreview${className ? ` ${className}` : ''}`}>
    <Emoji emoji={status.emoji?.colons || status.emoji} size={20} />
    <UserStatusLabel status={status} />
  </div>
));

export default UserStatusPreview;
